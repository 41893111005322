import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
// import Testimonials from '../components/Testimonials'
import { PageProps } from 'gatsby'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import AnchorScroll from '../components/AnchorScroll'
import Form from '../components/Form'
import QuoteContainer from '../components/QuoteContainer'
import QuoteForm from '../components/QuoteForm'
import useFormError from '../hooks/useFormError'
import useQuoteCustomBrandsOptions from '../hooks/useQuoteCustomBrandsOptions'
import useQuoteCustomModelsOptions from '../hooks/useQuoteCustomModelsOptions'
import { QuoteCustomFormData } from '../models/QuoteCustomFormData'
import { QuoteCustomRequest } from '../models/QuoteCustomRequest'
import { QuoteCustomResponse } from '../models/QuoteCustomResponse'
import fetchSaveApi from '../utils/fetchSaveApi'
import quoteCustomFormSchema, {
  modelCustomValue,
} from '../validations/quoteCustomFormSchema'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import { Option as IOption } from '../models/Option'

const ExternalContactFormPage: React.FC<PageProps> = () => {
  const ref = useRef<HTMLDivElement | null>(null)

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<QuoteCustomFormData>({
    resolver: yupResolver(quoteCustomFormSchema),
    mode: 'onChange',
  })

  const [formError, setFormError] = useFormError(isSubmitting)

  const [result, setResult] = useState<QuoteCustomFormData | undefined>(
    undefined
  )

  const isBrowser = () => typeof window !== 'undefined'
  useEffect(() => {
    const params = isBrowser()
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams()
    if (params.get('shopId')) {
      setValue('shopId', params.get('shopId')!)
    }
  }, [])

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit = handleSubmit(async (data) => {
    data.brand = brandSelected ? brandSelected.value : ''
    data.modele = modelSelected ? modelSelected.value : ''
    const request: QuoteCustomRequest = {
      shopId: data.shopId,
      magasinid: data.shopId,
      brand: data.brand,
      modele:
        data.modele === modelCustomValue && data.modeleCustom
          ? data.modeleCustom
          : data.modele,
      webSiteOrigin: 'SAVE_CO',
      description: data.description,
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      optin1: data.optin1,
      optin2: data.optin2,
      hgHomeProcess: false,
      devisDetailAsList: [],
    }

    try {
      if (recaptchaRef.current) {
        const recaptchaToken = await recaptchaRef.current.executeAsync()

        if (recaptchaToken) {
          const resp = await fetchSaveApi<QuoteCustomResponse>(
            '/website/v1/quote/form',
            {
              method: 'POST',
              body: JSON.stringify(request),
            }
          )
          // Reset reCAPTCHA after submission
          recaptchaRef.current.reset()
          if (!resp?.reference) {
            setFormError({
              type: 'required',
              message: "Une erreur est survenue lors de l'envoi du devis",
            })
            return
          }

          setResult(data)
          reset()
          requestAnimationFrame(() => scrollTo(0, 0))
        }
      }
    } catch (e) {
      console.error(e)
      setFormError({
        type: 'required',
        message: "Une erreur est survenue lors de l'envoi du devis",
      })
    }
  })

  const [brandSelected, setBrandSelected] = useState<IOption>()
  const [modelSelected, setModelSelected] = useState<IOption>()
  const selectBrand = useCallback(
    (optionSelected: IOption) => {
      setBrandSelected(optionSelected)
      setModelSelected({ label: 'Sélectionnez votre modèle', value: '' })
    },
    [brandSelected]
  )

  const modelEnabled = useMemo(
    () => brandSelected && brandSelected.value.trim().length > 0,
    [brandSelected]
  )

  const quoteCustomBrandsOptions = useQuoteCustomBrandsOptions()

  let modelOptionsOriginal = useQuoteCustomModelsOptions(
    brandSelected?.value ?? ''
  )

  const modelOptions = useMemo(
    () => [
      { label: 'Mon modèle n‘est pas dans la liste', value: modelCustomValue },
      ...modelOptionsOriginal,
    ],
    [modelOptionsOriginal]
  )

  const showModelCustom = useMemo(
    () => modelSelected?.value === modelCustomValue,
    [modelSelected]
  )
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Envoyez une demande de contact à Save !"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="frame-ancestors 'self' https://magasin.save.co/ https://staging-storeloc-save.partoo-store-locator.co/;"
        />
      </Helmet>
      <QuoteForm onSubmit={onSubmit}>
        <AnchorScroll ref={ref} />

        <QuoteForm.ExternalContactForm>
          <Form.Input
            {...register('lastname')}
            error={errors.lastname}
            label="* Nom"
          />
          <Form.Input
            {...register('firstname')}
            error={errors.firstname}
            label="* Prénom"
          />
          <Form.Input
            {...register('email')}
            error={errors.email}
            label="* Adresse email"
          />
          <Form.Input
            {...register('phone')}
            error={errors.phone}
            label="Numéro de téléphone"
          />

          <Form.LabelAsterix>
            Tous les champs précédés d'une astérisque (*) sont nécessaires pour
            la soumission du formulaire et le traitement de votre demande.
          </Form.LabelAsterix>

          <Form.Select
            className="brand"
            error={errors.brand}
            value={brandSelected}
            onChange={selectBrand}
            options={quoteCustomBrandsOptions}
            placeholder="Sélectionnez la marque de votre mobile"
            label="Votre marque"
          />
          <Form.Select
            className="model"
            onChange={setModelSelected}
            value={modelSelected}
            error={errors.modele}
            options={modelOptions}
            label="Votre modèle"
            disabled={!modelEnabled}
            placeholder="Sélectionnez votre modèle de mobile"
          />
          {showModelCustom && (
            <Form.Input
              className="modelCustom"
              {...register('modeleCustom')}
              error={errors.modeleCustom}
              label="Entrez votre modèle"
            />
          )}

          <Form.Textarea
            className="description"
            {...register('description')}
            error={errors.description}
            minRows={6}
            label="* Message"
          />
          <Form.Options>
            <Form.Option
              {...register('optin1')}
              error={errors.optin1}
              label="J’accepte de recevoir des emails ciblés et automatiques en fonction de mes données de navigation et de mes intérêts."
            />
            <Form.Option
              {...register('optin2')}
              error={errors.optin2}
              label="J’accepte de recevoir des promotions ponctuelles (soldes, opérations spéciales...) de Save."
            />
          </Form.Options>
          <ReCAPTCHA
            sitekey="6LdB81QmAAAAAB7LXjxq9aYhw4S_5Ae7VLsCi-7u"
            ref={recaptchaRef}
            size="invisible"
          />
          <Form.Submit
            disabled={isSubmitting}
            value="Valider ma demande de contact"
            error={formError || errors.shopId}
            success={result != undefined}
          />
        </QuoteForm.ExternalContactForm>
      </QuoteForm>
    </>
  )
}

export default Object.assign(ExternalContactFormPage)
